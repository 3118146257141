import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path2/src/components/DefaultLayout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Recipe = makeShortcode("Recipe");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Obtaining`}</h2>
    <h3>{`Smelting`}</h3>
    <Recipe item="carbon" inputs="ore,wood" mdxType="Recipe" />
    <h2>{`Usage`}</h2>
    <h3>{`Crafting`}</h3>
    <Recipe item="battery_pack" inputs="lithium,carbon,circuit" mdxType="Recipe" />
    <h3>{`Smelting`}</h3>
    <Recipe item="steel" inputs="carbon,iron" mdxType="Recipe" />
    <h3>{`Cooking`}</h3>
    <Recipe item="lye" inputs="bottle_water,carbon" mdxType="Recipe" />
    <h3>{`Fuel`}</h3>
    <p>{`Carbon is used as fuel in the furance. The higher burning temperature of carbon (compared to wood) is required to combine metals to produce alloys.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      